<template>
  <main class="page">
    <section class="section section--after-payment">
      <div class="text-center">
        <div class="after-payment__content">
          <h2 class="title title-md">
            {{ $t('web.success_congratulations-title') }}
          </h2>
          <div class="mt-3">
            <div class="spinner-border color-main" role="status"></div>
          </div>
          <p class="text text-md py-3">
            {{ $t('web.success_congratulations-description') }}
          </p>
          <div>
            <c-link
              class="text text-md"
              :to="{ name: $ROUTER.NAME.ACCOUNT.PANEL }"
              nowrap
              :label="$t('web.success-payment_go-to-dashboard')"
            />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import CLink from '@/components/Link'
export default {
  name: 'Success',
  components: {
    CLink,
  },

  data() {
    return {
      isTimoutForReadingFinished: false,
    }
  },

  watch: {
    isTimoutForReadingFinished(val) {
      if (val) {
        if (this.$store.getters['user/token']) {
          this.$router.push({ name: this.$ROUTER.NAME.ACCOUNT.PANEL })
        } else {
          this.$router.push({ name: this.$ROUTER.NAME.AUTH.LOGIN })
        }
      }
    },
  },

  mounted() {
    this.init()
  },

  methods: {
    init() {
      if (this.$route.query?.from_merchant === 'true') {
        setTimeout(() => {
          this.isTimoutForReadingFinished = true
        }, 4000)
      } else {
        this.$router.push({ name: this.$ROUTER.NAME.HOME })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../index';
</style>
